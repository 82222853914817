<template>
  <div class="vacancy-card">
    <span class="vacancy-label">Full Time</span>
    <div class="vacancy-image">
      <img :src="`/images/dist/${vacancy.alias}.svg`" :alt="vacancy.stack" />
    </div>
    <div class="vacancy-stack">{{ vacancy.stack }}</div>
    <div class="vacancy-name">
      {{ vacancy.level }} <br />
      {{ vacancy.name }}
    </div>
    <router-link
      :to="{ name: 'Vacancy', params: { slug: vacancy.alias } }"
      class="btn btn-gradient"
      >Подробнее</router-link
    >
  </div>
</template>

<script>
export default {
  name: "VacancyCard",
  props: {
    vacancy: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
