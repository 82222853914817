<template>
  <div>
    <section class="section section-about" id="about">
      <div class="container">
        <div class="row">
          <div class="about-card">
            <div>
              <h3 class="h3-text white--text">Кто мы?</h3>
              <p class="white--text fw-light body-text">
                Креативная команда закрытого, семейного типа. Формируем
                коллектив специалистов с нестандартными подходами к рабочим
                процессам.
              </p>
            </div>
          </div>
          <div class="about-details">
            <div class="about-detail">
              <div
                class="about-detail-icon image-icon image-icon--rocket"
              ></div>
              <div class="about-title">Что мы делаем?</div>
              <div class="about-content">
                Разрабатываем продукты и предоставляем услуги в сегменте
                e-commerce, которые объединяют инновационные IT-решения,
                аналитику бизнес процессов, мониторинг рынка, консалтинг и новые
                возможности для бизнеса
              </div>
            </div>
            <div class="about-detail">
              <div class="about-detail-icon image-icon image-icon--list"></div>
              <div class="about-title">
                Внутренние процессы
              </div>
              <div class="about-content">
                Создаем и внедряем инновационные решения для собственных бизнес
                процессов (IT разработка, Менеджмент, Маркетинг, Аналитика) в
                различных сферах.
              </div>
            </div>
            <div class="about-detail">
              <div class="about-detail-icon image-icon image-icon--brain"></div>
              <div class="about-title">
                Новый вызов - новые возможности
              </div>
              <div class="about-content">
                Предоставляем возможность работы над высоконагруженными
                проектами напрямую с потребителями продуктов.
              </div>
            </div>
            <div class="about-detail">
              <div class="about-detail-icon image-icon image-icon--world"></div>
              <div class="about-title">
                Не ограничивай себя
              </div>
              <div class="about-content">
                Работаем с аудиториями: США, Канады, Великобритании, Украины,
                Европы и других уголков мира!
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-join" id="join">
      <div class="container">
        <div class="join-us-bg blue--text">
          <div class="row">
            <div class="left-block">
              <h3 class="h3-text">Присоединяйся к нам!</h3>
              <div class="join-list">
                <div class="join-list--item">
                  <div class="image-icon image-icon--dollar"></div>
                  Нужен стабильный доход?
                </div>
                <div class="join-list--item">
                  <div class="image-icon image-icon--worker"></div>
                  Надоела работа на фрилансе?
                </div>
                <div class="join-list--item">
                  <div class="image-icon image-icon--idea"></div>
                  Желаешь развивать и реализовывать свои идеи?
                </div>
              </div>
              <a
                :href="`mailto:${links.email}`"
                class="btn btn-large btn-gradient btn-connect"
              >
                Присоединяйся <span class="wg-arrow"></span>
              </a>
            </div>

            <div class="right-block">
              <div class="image-join"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-info" id="info">
      <div class="container">
        <div class="row">
          <div class="image-info image-info--left"></div>
          <div class="info-text first-info">
            <div class="h3-text info-title">
              Наш дом - наша крепость
            </div>
            <p>Уютный, светлый и современный офис в центре города.</p>
          </div>
        </div>
        <div class="row">
          <div class="info-text">
            <div class="h3-text info-title">
              Гибкость
            </div>
            <p>Мы понимаем важность баланса между работой и личной жизнью.</p>
            <p>Карьера в WGroup позволяет в полной мере наслаждаться жизнью.</p>
          </div>
          <div class="image-info image-info--right"></div>
        </div>
      </div>
    </section>
    <section class="section section-vacancies" id="vacancies">
      <div class="container">
        <div class="vacancies-bg">
          <h3 class="h3-text text-center">Наши Вакансии</h3>
          <div class="row">
            <div class="vacancies-wrap">
              <VacancyCard
                v-for="vacancy in vacancies"
                :key="vacancy.alias"
                :vacancy="vacancy"
              />
            </div>
          </div>
          <div class="text-center">
            <router-link
              class="btn btn-large btn-gradient btn-connect"
              :to="{ name: 'Vacancies' }"
              >Все вакансии <span class="wg-arrow"></span
            ></router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VacancyCard from "@/components/VacancyCard";
import links from "@/data/links.json";

export default {
  name: "Index",
  components: { VacancyCard },
  data() {
    return {
      links,
      vacancies: []
    };
  },
  methods: {
    async getVacancies() {
      const response = await fetch("/api/vacancies.json");
      const { vacancies } = await response.json();
      this.vacancies = Object.values(vacancies)
        .filter(vacancy => vacancy.active)
        .slice(0, 3);
    }
  },
  async mounted() {
    await this.getVacancies();
  }
};
</script>

<style scoped></style>
